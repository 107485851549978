import React, { useState, useEffect } from "react";
import { calcularTotales, CREDITO, IGV } from "../../../Global";
import { defaultPostHeaders } from "../../../helpers/wrappers";
import { notificarError, notificarMsg } from "../../Almacenes/AlmacenNotify";
import moment from "moment";
import {
  getPreventaFromDB,
  getVentaCalculada,
  getDetallesPreventaFromDB,
} from "../shared";
import { BotonCancelarLetras } from "../../Plantillas/Botones";

export default function (props) {
  const [observacion, setObservacion] = useState("");
  const [venta, setVenta] = useState(undefined);
  const [fechaEntrega, setFechaEntrega] = useState(
    moment(new Date()).add(7, "days").format("YYYY-MM-DD")
  );

  const savePreventa = async () => {
    const totales = calcularTotales(props.items);

    const payload = {
      detalles: props.items.map((item) => ({
        IdPresentacion: item.IdPresentacion,
        IdProducto: item.IdProducto,
        EsFacturable: item.EsFacturable,
        ValorUnitario: item.ValorUnitario,
        Gratuito: item.Gratuito,
        Descuento: item.Descuento,
        IdAfectacionIgv: item.IdAfectacionIgv,
        IdStock: item.IdStock,
        IdTipoStock: item.IdTipoStock,
        Precio: item.Precio,
        TipoCambio: item.TipoCambio,
        IdAlmacen: item.IdAlmacen,
        PrecioReferencial: item.PrecioReferencial,
        PrecioVenta: item.PrecioVenta,
        Cantidad: item.Cantidad,
        Total: item.Total,
      })),
      IdTipoDocumentoSunat: 1,
      IdCliente: props.client.IdCliente,
      Total: totales.total,
    };

    try {
      for (let detalle of payload.detalles) {
        await fetch(
          `/api/pedidos/alterStock/${detalle.IdStock}/${detalle.Cantidad}`
        );
      }

      const req = await fetch("/api/pre-invoice/add/v2", {
        headers: { ...defaultPostHeaders() },
        method: "POST",
        body: JSON.stringify(payload),
      });

      if (!req.ok) {
        throw new Error("Ocurrio un error al registrar");
      }

      return await req.json();
    } catch (e) {
      notificarError(e.message);
    }
  };

  const saveVenta = async (preventa, detalles) => {
    const ventaCalculada = getVentaCalculada(preventa, detalles);
    const totales = calcularTotales(detalles);

    const payload = {
      IdCliente: props.client.IdCliente,
      IdTipoOperacion: preventa.IdPreventa,
      TipoTabla: "Preventas",
      Estado: preventa.Estado,
      IdModalidadPago: CREDITO,
      IdTipoComprobante: 1,
      IdMoneda: preventa.IdMoneda,
      TipoCambio: preventa.TipoCambio,
      TasaIGV: preventa.TasaIGV,
      TasaICBPER: preventa.TasaICBPER,
      DescuentoTotal: 0,
      Gravadas: totales.gravados,
      Exoneradas: totales.exonerados,
      Inafectas: totales.inafectos,
      ICBPER: totales.icbper,
      Exportacion: 0.0,
      Total: totales.total,
      Vuelto: 0,
      Observacion: observacion,
      IGV: totales.gravados * IGV,
      Gratuitas: totales.gratuitos,
      Redondeo: totales.redondeo,
      PorcentajeDescuento: ventaCalculada.PorcentajeDescuento,
      IVAP: ventaCalculada.IVAP,
      TasaIVAP: ventaCalculada.TasaIVAP,
      TasaISC: ventaCalculada.ISC,
      descuento: ventaCalculada.Descuento,
      pagosInfo: JSON.stringify([]),
    };

    try {
      const req = await fetch("/api/ventas/new", {
        headers: { ...defaultPostHeaders() },
        method: "POST",
        body: JSON.stringify(payload),
      });

      if (!req.ok) {
        throw new Error("Ocurrio un error al registrar");
      }

      const res = await req.json();

      return res[0][0][0];
    } catch (e) {
      notificarError(e.message);
    }
  };

  const savePedido = async (venta, preventaId) => {
    const payload = {
      IdVenta: venta.IdRegistroVenta,
      IdPreventa: preventaId,
      Observacion: observacion,
      FechaRegistro: `${moment(new Date()).format("YYYY-MM-DD")} ${moment(
        new Date()
      ).format("HH:mm")}`,
      FechaEntrega: `${moment(fechaEntrega).format("YYYY-MM-DD")} 23:59`,
    };

    try {
      const req = await fetch("/api/pedidos/", {
        body: JSON.stringify(payload),
        method: "POST",
        headers: { ...defaultPostHeaders() },
      });

      if (!req.ok) {
        throw new Error("No se pudo registrar el pedido");
      }

      const response = await req.json();

      props.onPedidoRegistered(response.pedidoId);
    } catch (e) {
      console.error(e);
    }
  };

  const saveDetalleVale = async (preventa, detalles, IdVale) => {
    const detallesCalculados = getVentaCalculada(preventa, detalles);

    const Items = detallesCalculados.Items.map((detalle) => {
      return [
        IdVale,
        detalle.IdStock,
        detalle.Cantidad,
        detalle.PrecioReferencial,
        detalle.PrecioVenta,
        detalle.IdAfectacionIgv,
        detalle.IdPresentacion,
        detalle.Total,
        detalle.Descuento,
        detalle.TipoCambio,
        detalle.ISC,
        detalle.IGV,
        detalle.ICBPER,
        detalle.IVAP,
        detalle.TasaISC,
        detalle.ValorUnitario,
        detalle.DescuentoSI,
        detalle.Gratuito,
      ];
    });

    try {
      const response = await fetch(`/api/ventas/detalles/vales/new`, {
        method: "POST",
        body: JSON.stringify({
          Items,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return await response.json();
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  };

  const save = async () => {
    const runAsync = async () => {
      const { IdPreventa } = await savePreventa();

      const preventa = await getPreventaFromDB(IdPreventa);
      const detalles = await getDetallesPreventaFromDB(IdPreventa);
      const venta = await saveVenta(preventa, detalles);

      saveDetalleVale(preventa, detalles, venta.IdRegistroVenta);

      setVenta(venta);

      await savePedido(venta, IdPreventa);
    };

    try {
      await runAsync();
      notificarMsg("El pedido se registró correctamente.");
    } catch (e) {
      notificarError("El pedido no se ha registrado. Intentelo otra vez.");
      console.error(e);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 mb-3">
          <div class="form-group">
            <label for="observacion">Observación</label>
            <textarea
              class="form-control"
              id="observacion"
              rows="4"
              value={observacion}
              disabled={!!venta}
              onChange={(e) => setObservacion(e.target.value)}
            ></textarea>
          </div>

          <div className="form-group">
            <label for="observacion">Fecha de entrega</label>
            <input
              type="date"
              value={moment(fechaEntrega).format("YYYY-MM-DD")}
              className="form-control"
              onChange={(e) => setFechaEntrega(e.target.value)}
            />
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-outline-primary btn-verde-mfp btn-block"
        onClick={save}
      >
        Guardar
      </button>

      <BotonCancelarLetras
        onClick={() => props.onCancel()}
        className="mb-2 mt-2 btn-block"
      />
    </>
  );
}
