import React, { useState, useEffect } from "react";
import "./SelectedProductVIewer.css"
import { decimalAdjust } from "../../Global";

function ListaProductos() {  
  const [items, setItems] = useState([]);
  const [totales, setTotales] = useState({});

  useEffect(() => {
    const channel = new BroadcastChannel("mfp-productos-seleccionados");

    channel.onmessage = (e) => {
        const data = JSON.parse(e.data);

        if (!('totales' in data) || !('detalles' in data)) {
            return;
        }

        setItems(data.detalles);
        setTotales(data.totales);
    }

    return () => channel.close();
  }, [])

  return (
    <div className='main'>
      <section className="section-rigth2">
          <div className="fijado">
            <span className='producto'>PRODUCTO</span>
            <span >/CANTIDAD</span>
            <span >/PRECIO</span>
            <span >/TOTAL</span>
          </div>
          <div className="productos-promo-scroll2">
              <div className="lista-producto-promo-general2">
                {items.map((det, index) => (
                  <div className="lista-producto-promo-content3" key={index}>
                      <div className="nombre-prunitario3">
                          <div className="detalles-producto3">
                              <p className="nombre-producto3">{`${det.descripcion} ${det.unidadMedida}`}</p>
                              <p className="cantidad-producto3">{det.Cantidad}</p>
                              <p className="precio-producto3">{`${det.moneda} ${det.PrecioVenta}`}</p>
                              <p className="stock-producto3">{decimalAdjust('round', det.Total, -2)}</p>
                          </div>
                      </div>
                  </div>
                ))}
              </div>
          </div>
      </section>
      <footer className='footer2'>
        {
            'total' in totales && <div className='seccion2-footer'>
                <span className='sub__total'>S/. {decimalAdjust('round', totales.total, -1)}</span>
            </div>
        }
      </footer>
    </div>
  );
}

export default ListaProductos;