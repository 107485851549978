import React , {useState, useEffect} from "react";


export function IdentifyClient({ onChangeClient }) {
    const [docType, setDocType] = useState("1");
    const [docNumber, setDocNumber] = useState("99999999")
    const [clientData, setClientData] = useState(null);
    const [clientName, setClientName] = useState("");

    useEffect(() => {
        const fetchDefaultClient = async () => {
            const client = await fetchClient();
            setClientData(client);
        }

        fetchDefaultClient()
    }, [])

    useEffect(() => {
        if (!clientData) {
            return;
        }

        if (clientData.IdTipoDocumento == 1) {
            setClientName(`${clientData.PrimerNombre} ${clientData.SegundoNombre} ${clientData.ApellidoPaterno} ${clientData.SegundoNombre}`)
        } else {
            setClientName(`${clientData.RazonSocial}`)
        }

        onChangeClient(clientData)
    }, [clientData]);

    const fetchClient = async () => {
        try {
            const req = await fetch(`/api/preVentas/traerCliente/${docNumber}`);

            if (!req.ok) {
                throw new Error(await req.text())
            }

            const data = await req.json();

            if (!Array.isArray(data)) {
                throw new Error("Se esperaba una respuesta distinta")
            }

            if (data.length === 0) {
                throw new Error("No se han encontrado resultados")
            }

            return data[0]
        } catch (e) {
            console.error(e)
        }

        return null;
    }

    useEffect(() => {
        if (typeof docNumber !== 'string') {
            return;
        }
        
        if (docNumber.length === 8 && /^\d{8}$/.test(docNumber)) {
            setDocType("1");
            return;
        }
        
        if (docNumber.length === 11 && /^(10|20)\d{9}$/.test(docNumber)) {
            setDocType("6");
            return;
        }
        
        setDocType("1");
    }, [docNumber])

    useEffect(() => {
        if (docNumber.length < 8) {
            return;
        }

        if (docNumber.length == 8 && (docNumber.startsWith('20') || docNumber.startsWith('10'))) {
            return;
        }

        const search = async () => {
            let client = null;

            if (docType == "1" && docNumber.length == 8) {
                client = await fetchClient();
            } else if (docType == "6" && docNumber.length == 11) {
                client = await fetchClient();
            }

            if (!client) {
                return;
            }

            setClientData(client)
        }

        search();
    }, [docNumber, docType])

    return <>
        <select name="selector" className='abed-preventa__tipo--comprobante--select' value={docType} onChange={e => setDocType(e.target.value)}>
            <option className='preventa__tipo--comprobante--option' value="6">FACTURA</option>
            <option className='preventa__tipo--comprobante--option' value="1">BOLETA</option>
        </select>
        <div className='abed-preventa__cliente'>
            <div className='abed-agregar_cliente'>
                <button type="button">
                <a className='abed-agregar_cliente_text'>Agregar Cliente</a>
                </button>
            </div>
            <div className='abed-ingresar_cliente'> 
                <input className='abed-DocCliente' type="number" placeholder='DNI/RUC' value={docNumber} onChange={e => setDocNumber(e.target.value)}/>
            </div>
        </div>
        {
            clientName != "" && <div className='abed-ingresar_cliente'>
                <span className="fw-bold">{clientName}</span>
            </div>
        }
    </>
}
