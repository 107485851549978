import React from "react";
import {BotonRegistrar, BotonCerrarCajaLetras} from "../Plantillas/Botones";
import ListMovimientos from "../movimientosCaja/ListaMovimientosPorTurno";
import Modal from "../Modal";
import ModalCliente from "../clientes/ClienteModal";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import TurnoCierre from "./TurnoCierre";
import _ from 'lodash';
import moment from 'moment';
import {registroMovCajaPDF} from "./printDataCaja";
import { BotonLetrasVerdeOscuro } from "../Plantillas/Botones"

export default class MovimientosCaja extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            movs: [],
            isLoading: false,
            doReload: false,
            TipoMovimiento: 'Entrada',
            Monto: 0,
            Observacion: '',
            modalIsOpen: false,
            modalIsOpenCierre: false,
            mostrarTipoMovimiento: false,
            buttonAddMov: false,
            cierreCompletado: false,
            pendingInvoices: [],
            showModalValidacion: false,
            codigosValidacion: [],
            codigoValidacionInput: "",
            codigoValidacionOk: false,
            validarMovEntrada: false,
            validarMovSalida: false,
        }
        this.handleCloseModalCierre = this.handleCloseModalCierre.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.handleChangeTipoMovimiento = this.handleChangeTipoMovimiento.bind(this);
        this.handleClickNuevoCierre = this.handleClickNuevoCierre.bind(this);
        this.handleCierreTurnoCompletado = this.handleCierreTurnoCompletado.bind(this);
        this.handleRefreshPage = this.handleRefreshPage.bind(this);
        this.comprobarEstadoComprobantes = this.comprobarEstadoComprobantes.bind(this);
        this.fetchConfigValidar = this.fetchConfigValidar.bind(this);
        this.fetchCodigoValidacion = this.fetchCodigoValidacion.bind(this);
        this.validarCodigoUsuario = this.validarCodigoUsuario.bind(this);
        this.handleCloseModalValidar = this.handleCloseModalValidar.bind(this);
        this.onValidarCodigoKeyDown = this.onValidarCodigoKeyDown.bind(this);
        this.handleChangeCodigo = this.handleChangeCodigo.bind(this);
    }

    async componentDidMount() {
        this.setState({isLoading: true})
        await this.obtenerMovimientos(this.state);
        this.setState({isLoading: false})
        await this.comprobarEstadoComprobantes();
        await this.fetchConfigValidar()
        await this.fetchCodigoValidacion();
    }

    async comprobarEstadoComprobantes() {
        this.setState({ pendingInvoices: [] });

        const idSucursal = await (async () => {
            const req = await fetch(`/api/usuarios/codigoConfirmacion/sucursal`);

            if (!req.ok) {
                return 0;
            }

            const data = await req.json();

            return data[0].IdSucursal;
        })();

        if (!idSucursal) {
            return;
        }

        try {
            const req = await fetch(`/api/ventas/filter-fe?idSucursal=${idSucursal}&fecha=${moment().format('YYYY-MM-DD')}`, {
                method: 'POST',
                body: JSON.stringify({
                    "page": 0,
                    "pageSize": 10000,
                    "sorted": [],
                    "filtered": []
                }),
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!req.ok) {
                throw new Error();
            }

            const data = await req.json();
            this.setState({ pendingInvoices: data.ventas.filter(x => ['Pendiente', 'Rechazado'].includes(x.EstadoCE)) })
        } catch (e) {
            notificarError("Ocurrió un error al verificar los documentos en estado pendiente.")
        }
    }

    async obtenerMovimientos() {
        try {
            const response = await fetch(`/api/cajas/`);
            const data = await response.json();
            this.setState({movs: data.movCajas})
        } catch (error) {
            notificarError("No se pudieron cargar los movimientos de caja.")
        }
    }

    async onSubmitHandler(e) {
        e.preventDefault()

        if (!this.state.codigoValidacionOk && this.state[`validarMov${this.state.TipoMovimiento}`]) {
            this.openModalValidacion();
            return;
        }

        this.setState({buttonAddMov: true})
        this.setState({isLoading: true})
        await this.addMovimientoCaja()
        await this.obtenerMovimientos();
        const data = {idTurno: this.props.idTurno, data: {...this.state}}
        await registroMovCajaPDF(data);
        this.setState({Observacion: '', Monto: 0, isLoading: false, buttonAddMov: false, codigoValidacionOk: false })
    }

    async addMovimientoCaja() {
        try {
            const res = await fetch(`/api/cajas/addMov`, {
                method: 'POST',
                body: JSON.stringify({
                    TipoMovimiento: this.state.TipoMovimiento,
                    Monto: this.state.Monto,
                    Saldo: 0,
                    MontoFinalEfectivoSistema: 0,
                    Observacion: this.state.Observacion
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
        } catch (err) {
            notificarError('No se pudo agregar el movimiento de caja.')
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target
        this.setState({
            [name]: e.target.getAttribute('type') === 'number' ? (_.isNumber(Number(value)) ? value : 0) : value
        })
    }

    handleCloseModalCierre() {
        this.setState({modalIsOpenCierre: false})
    }


    handleChangeTipoMovimiento(e) {
        this.setState({
            TipoMovimiento: e.target.value
        })
    }

    getBotonTipoComprobante(tipoMovimiento, NombreDocSunat) {
        return (
            <>
                <label htmlFor="TipoMovimiento">Tipo de Movimiento</label>
                <select
                    id="TipoMovimiento"
                    name="TipoMovimiento"
                    className="form-control"
                    value={this.state.TipoMovimiento}
                    onChange={this.handleChangeTipoMovimiento}
                >
                    <option value="Entrada" selected={this.state.TipoMovimiento === 'Entrada'} className="select-search-box__options">Entrada</option>
                    <option value="Salida" selected={this.state.TipoMovimiento === 'Salida'} className="select-search-box__options">Salida</option>
                </select>
            </>
        );
    }

    handleClickNuevoCierre(e) {
        this.setState({
            modalIsOpenCierre: true
        })
    }

    handleClickTipoMovimiento = () => {
        this.setState({ mostrarTipoMovimiento: true });
    }

    handleCierreTurnoCompletado = () => {
        this.setState({ modalIsOpenCierre: false, cierreCompletado: true });
    }

    handleRefreshPage = () => {
        window.location.reload();
    }

    openModalValidacion(objAnular) {
        this.setState({facturacionInfo: objAnular})
        this.setState({showModalValidacion: true})
    }

    async fetchConfigValidar() {
        this.setState({loading: true});
        let res = await fetch('/api/cajas/validar');
        const {
            movEntrada,
            movSalida
        } = await res.json();

        this.setState({
            loading: false,
            validarMovEntrada: movEntrada,
            validarMovSalida: movSalida,
        }) 
    }

    async fetchCodigoValidacion() {
        this.setState({loading: true});
        const req = await fetch('/api/usuarios/codigoConfirmacion/sucursal');
        const res = await req.json();

        try {
            if (req.ok)
                var codigos = res.map(c => c.Codigo)
            this.setState({codigosValidacion: codigos})
        } catch (e) {
            console.error(e)
        }

        this.setState({loading: false});
    }

    async validarCodigoUsuario() {
        const codigoInput = this.state.codigoValidacionInput;
        const codigosUsuarios = this.state.codigosValidacion;

        this.setState({codigoValidacionInput: ""});

        const validCode = codigosUsuarios.some(c => c == codigoInput)

        if (!validCode) {
            notificarMsg('El codigo ingresado no es correcto.', 'warning')
            return;
        }
        
        notificarMsg("Validacion completada exitosamente.");
        this.setState({ showModalValidacion: false, codigoValidacionOk: true }, () => {
            this.onSubmitHandler({ preventDefault: () => {} })
        });        
    }

    handleCloseModalValidar = (e) => {
        this.setState({
            showModalValidacion: false
        })
    }

    handleChangeCodigo = (text) => {
        const value = text.target.value
        this.setState({codigoValidacionInput: value});
    }

    onValidarCodigoKeyDown(event) {
        if (event.key == "Enter") {
            this.validarCodigoUsuario();
        }
    }

    render() {
        const {extImg, idTurno, idCaja} = this.props;

        if (this.state.cierreCompletado) {
            return <section className="ventas-fpay-section-vh cienvh">
                <div className="preventa__container2">
                    <div className="container mt-5">
                        <div className="encabezado">
                            <div className='mt-5 mb-2 instruccion-caja text-center' style={{textAlign: 'justify'}}>
                                Se ha completado exitosamente el cierre de caja.
                            </div>

                            <BotonLetrasVerdeOscuro
                                className="mt-5 d-flex justify-content-center mt-2 aperturar-caja ml-auto mr-auto"
                                onClick={this.handleRefreshPage}>
                                Recargar página
                            </BotonLetrasVerdeOscuro>
                        </div>
                    </div>
                </div>
            </section>
        }

        return (
            <section className="ventas-fpay-section-vh cienvh">
                <div className="preventa__container2">
                    <div className="container mt-5">
                        <div className="encabezado">
                            { this.state.pendingInvoices.length > 0 && <div className="alert alert-warning mt-3 my-2">
                                <strong>Importante:</strong> Hay comprobantes pendientes por enviar a SUNAT. <a href="/facturacionelectronica"><i class="fas fa-external-link-alt"></i> Ir a facturación electrónica</a>
                            </div>}

                            <BotonCerrarCajaLetras
                                    className="mt-1 w-100 borde-cerrar-caja" onClick={this.handleClickNuevoCierre}
                                    title="Cerrar Caja"/>

                            <div>
                                {!this.state.mostrarTipoMovimiento && (
                                    <button className="registrar-movimiento my-2" onClick={this.handleClickTipoMovimiento}>Seleccione el tipo de movimineto</button>
                                )}
                                {this.state.mostrarTipoMovimiento && (
                                    <div className="preventa__tipo--comprobante">
                                        <div className="mt-2 caja-fondo-blanco-m-2">
                                            {this.getBotonTipoComprobante("Entrada", "Entrada")}
                                            
                                            <hr />

                                            <form onSubmit={this.onSubmitHandler} className="d-flex justify-content-center w-100">
                                                <div className="almacenDiv w-100">
                                                        <b className="">Monto:</b>
                                                        <div className="input__Almacen">
                                                            <input className="input__linea pl-2" name="Monto"
                                                                onChange={this.handleChange}
                                                                onFocus={(e) => e.target.select()}
                                                                type="number" step="any" min="0.01"
                                                                value={this.state.Monto}>
                                                            </input>
                                                        </div>
                                                        <div className="input__Almacen mt-2">
                                                            <b className="">Observación:</b>
                                                            <div className="input__Almacen">
                                                                <input className="input__linea pl-2" onChange={this.handleChange}
                                                                    name="Observacion"
                                                                    value={this.state.Observacion} />
                                                            </div>
                                                            <div align="right" className="pt-3">
                                                                <BotonRegistrar className="mt-2 mb-2"  disabled={this.state.buttonAddMov} />
                                                            </div>
                                                        </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )}

                            </div>

                            {this.state.mostrarTipoMovimiento && (
                            <div className="preventa__tipo--comprobante">
                                
                            </div>
                            )}

                            <div className="d-flex justify-content-center w-100">
                                    {this.state.isLoading ? 'Cargando' : <ListMovimientos movs={this.state.movs} idTurno={this.props.idTurno}/>}
                                </div>
                        </div>
                    </div>
                </div>

                <ModalCliente isOpen={this.state.showModalValidacion} onClose={() => this.setState({ showModalValidacion: false })} width='400px'
                    title="Validar Anulación de Comprobante">
                    <label className="font-weight-bold" htmlFor="inputValidacion">
                        Codigo
                    </label>
                    <input type="password"
                        name="inputValidacion"
                        value={(this.codigoValidacionInput)}
                        className="form-control mb-2"
                        onChange={this.handleChangeCodigo}
                        onKeyDown={this.onValidarCodigoKeyDown}/>

                    <div className="d-flex justify-content-end">
                    <button
                        className="btn btn-verde-mfp-letra-normal"
                        title="Validar"
                        onClick={() => {this.validarCodigoUsuario()}}
                    >Validar</button>
                    </div>
                </ModalCliente>

                <Modal
                    isOpen={this.state.modalIsOpenCierre} onClose={this.handleCloseModalCierre}
                    title="CERRAR CAJA"
                >
                    <TurnoCierre extImg={extImg} idTurno={idTurno} idCaja={idCaja}
                                 movsCaja={this.state.movs} montoInicial={this.props.montoInicial} onCierreTurnoCompletado={this.handleCierreTurnoCompletado}/>
                </Modal>
            </section>
        )
    }
}
