import React, {useState, useEffect}  from "react";
import Moment from 'react-moment';
import 'moment/locale/es';
import {useSelector} from "react-redux";
import {
    AMERICAN,
    decimalAdjust,
    EFECTIVO,
    ESTADO_CE,
    ESTADO_VALE,
    ESTADO_VENTA,
    MASTER,
    OTROS,
    PAGO_CREDITO,
    PLIN,
    RAPPI,
    TUNKI,
    VISA,
    YAPE,
  } from "../../Global";
import { calcularMovsCaja } from "../../helpers/utils";


const getTotalByComprobante = async (idTurno, idsTipoPago, estadoVenta, estadoVale) => {
    let queryParams = `idsTipoPago=${idsTipoPago}&
            idTurnoCaja=${idTurno}&
            estadoVenta=${estadoVenta}&
            estadoVale=${estadoVale}
            `.replace(/\s/g, "");
    let response = await fetch(`/api/turnos/total-ventas?${queryParams}`);
    let resJson = await response.json();
    return Number(resJson.total);
}


const calularTotalEfectivo = async (idTurno, anuladosTarjetas, totalIngresos, salidasNormales) => {
    const totalEfectOk = await getTotalByComprobante(
        idTurno,
        EFECTIVO,
        `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`,
        `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`,
    );
    const totalEfectBad = await getTotalByComprobante(
        idTurno,
        EFECTIVO,
        `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`,
        `${ESTADO_VALE.ANULADO}`,
    );

    return [
      totalEfectOk -
        totalEfectBad +
        totalIngresos -
        salidasNormales -
        anuladosTarjetas,
      totalEfectOk,
      totalEfectBad,
    ];
  }

const calcularTotalMaster = async (idTurno) => {
    const totalMasterOk = await getTotalByComprobante(
        idTurno,
        MASTER,
        `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`,
        `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`,
    );
    const totalMasterBad = await getTotalByComprobante(
        idTurno,
        MASTER,
        `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`,
        `${ESTADO_VALE.ANULADO}`,
    );
    return [totalMasterOk, totalMasterBad];
  }

const calcularTotalVisa = async (idTurno) => {
    const totalVisaOk = await getTotalByComprobante(
        idTurno,
        VISA,
        `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`,
        `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`,
    );
    const totalVisaBad = await getTotalByComprobante(
        idTurno,
        VISA,
        `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`,
        `${ESTADO_VALE.ANULADO}`,
    );
    return [totalVisaOk, totalVisaBad];
  }

const calcularTotalAmerican = async (idTurno) => {
    const totalAmericanOk = await getTotalByComprobante(
        idTurno,
        AMERICAN,
        `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`,
        `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`,
    );
    const totalAmericanBad = await getTotalByComprobante(
        idTurno,
        AMERICAN,
        `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`,
        `${ESTADO_VALE.ANULADO}`,
    );

    return [totalAmericanOk, totalAmericanBad];
}

const calcularTotalOtros = async (idTurno) => {
    const totalOtrosOk = await getTotalByComprobante(
        idTurno,
        OTROS,
        `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`,
        `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`,
    );
    const totalOtrosBad = await getTotalByComprobante(
        idTurno,
        OTROS,
        `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`,
        `${ESTADO_VALE.ANULADO}`,
    );
    
    return [totalOtrosOk, totalOtrosBad];
}

const calcularTotalYape = async (idTurno) => {
    const totalOtrosOk = await getTotalByComprobante(
        idTurno,
        YAPE,
        `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`,
        `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`,
    );
    const totalOtrosBad = await getTotalByComprobante(
        idTurno,
        YAPE,
        `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`,
        `${ESTADO_VALE.ANULADO}`,
    );
    return [totalOtrosOk, totalOtrosBad];
}

const calcularTotalTunki = async (idTurno) => {
    const totalOtrosOk = await getTotalByComprobante(
        idTurno,
        TUNKI,
        `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`,
        `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`,
    );
    const totalOtrosBad = await getTotalByComprobante(
        idTurno,
        TUNKI,
        `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`,
        `${ESTADO_VALE.ANULADO}`,
    );
    return [totalOtrosOk, totalOtrosBad];
}

const calcularTotalRappi = async (idTurno) => {
    const totalOtrosOk = await getTotalByComprobante(
        idTurno,
        RAPPI,
        `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`,
        `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`,
    );
    const totalOtrosBad = await getTotalByComprobante(
        idTurno,
        RAPPI,
        `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`,
        `${ESTADO_VALE.ANULADO}`,
    );
return [totalOtrosOk, totalOtrosBad];
}

const calcularTotalPlin = async (idTurno) => {
    const totalOtrosOk = await getTotalByComprobante(
        idTurno,
        PLIN,
        `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`,
        `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`,
    );
    const totalOtrosBad = await getTotalByComprobante(
        idTurno,
        PLIN,
        `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`,
        `${ESTADO_VALE.ANULADO}`,
    );

    return [totalOtrosOk, totalOtrosBad];
}

const calcularTotalesGlobal = async (idTurno, movimientosCaja) => {
    let [entradas, salidas, salidasComprobante, salidasNormales] = calcularMovsCaja(movimientosCaja);
    let [totalOkVisa, totalBadVisa] = await calcularTotalVisa(idTurno);
    let [totalOkAmerican, totalBadAmerican] = await calcularTotalAmerican(idTurno);
    let [totalOkOtros, totalBadOtros] = await calcularTotalOtros(idTurno);
    let [totalOkYape, totalBadYape] = await calcularTotalYape(idTurno);
    let [totalOkTunki, totalBadTunki] = await calcularTotalTunki(idTurno);
    let [totalOkRappi, totalBadRappi] = await calcularTotalRappi(idTurno);
    let [totalOkPlin, totalBadPlin] = await calcularTotalPlin(idTurno);
    let [totalOkMaster, totalBadMaster] = await calcularTotalMaster(idTurno);

    let totalAnuladosTarjetas = 0;

    let [totalEfectivoVentas, totalEfectOk, totalEfectBad] =
      await calularTotalEfectivo(
        idTurno,
        totalAnuladosTarjetas,
        entradas,
        salidasNormales,
      );
    
    return {
        totalEfectivo: totalEfectivoVentas,
        totalTarjetas: (totalOkMaster - totalBadMaster) + (totalOkVisa - totalBadVisa) + (totalOkAmerican - totalBadAmerican) + (totalOkOtros - totalBadOtros),
        totalBilleterasDigitales: (totalOkYape - totalBadYape) + (totalOkTunki - totalBadTunki) + (totalOkRappi - totalBadRappi) + (totalOkPlin - totalBadPlin),
        entradas,
        salidas: salidas + salidasComprobante
    }
  }

function MovimientoCaja({ movimiento }) {
    return <>
        <li className="list-group-item">
            <span className="font-weight-bold p-0">Hora:</span> <Moment locale="es" format="DD-MMM-YYYY">{movimiento.FechaHora}</Moment> <Moment locale="es-ES" format="h:mm:ss a">{movimiento.FechaHora}</Moment>
            <br />
            <span className="font-weight-bold p-0">Observación:</span> {movimiento.Observacion}
            <br />
            <span className="font-weight-bold p-0">Monto:</span> S/ {movimiento.Monto}
        </li>
    </>
}

const DEFAULT_TOTALES = {
    totalEfectivo: 0,
    totalTarjetas:  0,
    totalBilleterasDigitales: 0,
    entradas: 0,
    salidas: 0
}

function ReporteDiarioCaja() {
    const [sucursal, setSucursal] = useState(null);
    const [cajas, setCajas] = useState([]);
    const [idTurnoCaja, setIdTurnoCaja] = useState(0);
    const [movimientosCaja, setMovimientosCaja] = useState([]);
    const {IdSucursal} = useSelector(state => state.sesion);
    const [caja, setCaja] = useState({ MontoInicial: 0 })
    const [totales, setTotales] = useState({ ...DEFAULT_TOTALES })

    useEffect(() => {
        fetchSucursal()
        fetchCajas();
    }, [])

    useEffect(() => {
        if (!idTurnoCaja) {
            return;
        }

        const refresh = async () => {
            const movimientos = await fetchMovimientosCaja()
            await calcularTotales(movimientos) 
        }

        refresh();
    }, [caja])

    const fetchSucursal = async () => {
        try {
            const req = await fetch(`/api/menus/sucursal?idSucursal=${IdSucursal}`)

            if (!req.ok) {
                throw new Error(await req.text())
            }

            const data = await req.json();

            setSucursal(data.sucursal);
        } catch (e) {
            console.error(e)
            setSucursal(null);
        }
    }

    const fetchCajas = async () => {
        try {
            const req = await fetch(`/api/cajas/info-sucursal`)

            if (!req.ok) {
                throw new Error(await req.text())
            }

            const data = await req.json();

            if (data.length > 0) {
                // setIdTurnoCaja(data[0].IdTurnoCaja)
            }

            setCajas(data);
        } catch (e) {
            console.error(e)
            setSucursal(null);
        }
    }

    const fetchMovimientosCaja = async () => {
        setMovimientosCaja([]);

        try {
            const req = await fetch(`/api/cajas//movimientosPorTurno/${idTurnoCaja}`);
            if (!req.ok) {
                throw new Error(await req.text())
            }

            const data = await req.json();

            setMovimientosCaja(data.movCajas)

            return data.movCajas;
        } catch (e) {
            console.error(e)
        }

        return []
    }

    const calcularTotales = async (movimientosCaja) => {
        const totales = await calcularTotalesGlobal(idTurnoCaja, movimientosCaja)
        setTotales(totales)
    }

    const onChangeCaja = (idTurnoCaja) => {
        setIdTurnoCaja(idTurnoCaja);

        if (!idTurnoCaja) {
            setCaja({ MontoInicial: 0 })
            return
        }
        const caja = cajas.find(c => c.IdTurnoCaja == idTurnoCaja);
        setCaja(caja);
    }

    if (!sucursal) {
        return null;
    }

    return <div className="ventas-fpay-section">
        <div className="pt-3 container">
            <div className="row justify-content-between">
                <div className="col-12 col-lg-4 caja-fondo-blanco-m-2">
                    <label className="sucursal-text-color">Sucursal</label>
                    <input type="text" className="input__linea" value={sucursal.Nombre} disabled/>
                </div>
                <div className="col-12 col-lg-4 caja-fondo-blanco-m-2">
                    <label className="sucursal-text-color">Sucursal</label>
                    <select
                        className="custom-select"
                        value={idTurnoCaja}
                        onChange={e => onChangeCaja(e.target.value)}
                    >
                        <option value={0}>-- Seleccionar --</option>
                        { cajas.map((caja, index) => <option key={index} value={caja.IdTurnoCaja}>{caja.Nombre} ({caja.Usuario})</option>)}
                    </select>
                </div>
            </div>
            {idTurnoCaja > 0 && <div className="row">
                <div className="col-6 mt-2">
                    <h3 className="mt-3">Ventas</h3>

                    <ul className="p-0">
                        <li style={{"background": "none"}} className="list-group-item border-0">
                            <span className="font-weight-bold p-0">Monto de apertura:</span> S/ {caja.MontoInicial.toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </li>
                        <li style={{"background": "none"}} className="list-group-item border-0">
                            <span className="font-weight-bold p-0">Efectivo:</span> S/ {totales.totalEfectivo.toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </li>
                        <li style={{"background": "none"}} className="list-group-item border-0">
                            <span className="font-weight-bold p-0">T. Tarjetas:</span> S/ {totales.totalTarjetas.toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </li>
                        <li style={{"background": "none"}} className="list-group-item border-0">
                            <span className="font-weight-bold p-0">T. Billeteras digitales:</span> S/ {totales.totalBilleterasDigitales.toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </li>
                        <li style={{"background": "none"}} className="list-group-item border-0">
                            <span className="font-weight-bold p-0">Total:</span> S/ {(+caja.MontoInicial + totales.totalEfectivo + totales.totalTarjetas + totales.totalBilleterasDigitales).toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </li>
                    </ul>
                    <h3>Resumen</h3>
                    <ul className="p-0">
                        <li style={{"background": "none"}} className="list-group-item border-0">
                            <span className="font-weight-bold p-0">Movimientos de salidas:</span> S/ {totales.salidas.toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </li>
                        <li style={{"background": "none"}} className="list-group-item border-0">
                            <span className="font-weight-bold p-0">Movimientos de entradas:</span> S/ {totales.entradas.toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </li>
                        <li style={{"background": "none"}} className="list-group-item border-0">
                            <span className="font-weight-bold p-0">Total:</span> S/ {(totales.entradas - totales.salidas).toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </li>
                    </ul>
                </div>
                <div className="col-6 border">
                    <div className="row mt-2">
                        <div className="col-12 mt-3">
                            <h4 className="font-weight-bold">MOVIMIENTOS DE SALIDAS</h4>
                            <ul className="p-0">
                                { movimientosCaja.filter(m => m.Tipo == "Salida").map(movimiento => <MovimientoCaja key={movimiento.IdMovimientoCaja} movimiento={movimiento}/>)}
                            </ul>
                        </div>
                        <div className="col-12 mt-3">
                            <h4 className="font-weight-bold">MOVIMIENTOS DE ENTRADAS</h4>
                            <ul className="p-0">
                                { movimientosCaja.filter(m => m.Tipo == "Entrada").map(movimiento => <MovimientoCaja key={movimiento.IdMovimientoCaja} movimiento={movimiento}/>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    </div>
}

export default ReporteDiarioCaja;
