import React from "react";
import PreInvoiceSubForm from "./PreInvoiceSubForm";
import {BOLETA, calcularTotales, decimalAdjust, FACTURA, IGV, VALE} from "../../Global";
import {defaultPostHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError} from "../Almacenes/AlmacenNotify";
import _ from "lodash";
import $ from 'jquery';
import {getPayloadFromPreVenta} from "../../helpers/utils";
import swal from "sweetalert";
import {printTicketPreventa} from "../../pages/RegistroVentas/Ticket";
import {numeroALetras} from "../Caja/printDataCaja";
import { MagellanStatus } from "./ThirdParty/MagellanStatus";

let _isMounted = false;

export default class PreInvoiceForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {},
            totales: {},
        }
        Object.defineProperty(this, 'isMounted', {
            get: () => _isMounted,
            set: v => {
                _isMounted = v
            }
        })
        this.onChangeSubForm = this.onChangeSubForm.bind(this)
        this.onAddPreInvoice = this.onAddPreInvoice.bind(this)
        this.bchannel = new BroadcastChannel("mfp-productos-seleccionados");
    }

    async componentDidMount() {
        this.isMounted = true
    }

    componentWillUnmount() {
        this.isMounted = false
        this.bchannel.close();
    }

    onChangeSubForm(data) {
        const totales = calcularTotales(data.detalles)

        this.bchannel.postMessage(JSON.stringify({
            totales,
            detalles: data.detalles
        }))

        this.setState((state, props) => {
            return {
                payload: {
                    ...state.payload,
                    ...data,
                    _totales: {...totales}
                },
                totales: {
                    Gravado: totales.gravados,
                    Inafecto: totales.inafectos,
                    Exonerado: totales.exonerados,
                    IGVPreventa: totales.gravados * IGV,
                    Gratuitas: totales.gratuitos,
                    totalMonto: totales.total,
                    ICBPERPreventa: totales.icbper,
                    redondeo: totales.redondeo,
                },
            }
        })
    }

    async onAddPreInvoice() {
        let tmpPayload = this.props.getNormalizedPayload ? this.props.getNormalizedPayload(this.state.payload) :
            this.getNormalizedPayload(this.state.payload);

        $(".trDetallePreventa").removeClass("alert alert-danger")

        pfetch({
            fetch: {
                url: this.props.endpointInvoice,
                headers: {...defaultPostHeaders(), accessCode: this.props.accessCode},
                method: "POST",
                body: JSON.stringify(tmpPayload)
            },
            before: () => this.setState({isLoading: true}),
            then: (v) => {

                const queryParams = new URLSearchParams(window.location.search);

                const next = queryParams.get('next');

                if (!!next) {
                    window.location.href = next
                } else {
                    this.props.onSuccessAdd(v)
                }
                
                if (this.props.imprimirPreventa) {
                    //this.props.showModalImprPreventa();
                    this.showModalImprimirPreventa();

                }
            },
            formErrors: (d) => this.showErrors(d),
            error: () => notificarError("No se pudo agregar la venta"),
            finally: () => {

                if (this.isMounted) {
                    this.setState({isLoading: false})
                }
            }
        })

    }

    async onPrintPreventaPdf() {
        const venta = {};
        const detalles = this.state.payload.detalles;
        venta.retencion = 0
        venta.Ruc = this.props.Ruc
        venta.Empresa = this.props.Empresa
        venta.Sucursal = this.props.Sucursal
        venta.Direccion = this.props.Direccion
        venta.Telefono = this.props.Telefono
        venta.TelefonoDos = this.props.TelefonoDos
        venta.TelefonoTres = this.props.TelefonoTres
        venta.DireccionSucursal = this.props.DireccionSucursal
        venta.TipoComprobante = "Preventa"
        venta.Serie = ""
        venta.NumeroComprobante = ""
        venta.Gravadas = this.state.totales.Gravado
        venta.Exoneradas = this.state.totales.exonerados + ""
        venta.Inafectas = this.state.totales.Inafecto + ""
        venta.Gratuitas = this.state.totales.Gratuitas + ""
        venta.IGV = this.state.totales.IGVPreventa
        venta.ICBPER = this.state.totales.ICBPERPreventa + ""
        venta.Total = this.state.totales.totalMonto + ""
        venta.ISC = "0"
        venta.IVAP = "0"
        venta.NroTipoDocumento = this.state.payload._client.NroTipoDocumento
        venta.RazonSocial = this.state.payload._client.RazonSocial
        venta.Simbolo = "S/"
        venta.IdModalidadPago = "ES UNA PREVENTA"
        const detalleImporte = detalles.map(x => {
            return {...x, Importe: x.PrecioVenta * x.Cantidad}
        })
        venta.Letras = await numeroALetras(decimalAdjust('floor', venta.Total, -2))
        await printTicketPreventa(detalleImporte, venta)
        //await printA4(detalles, venta);
    }


    async showModalImprimirPreventa() {
        try {
            let isOK = await swal({
                title: 'Aviso ',
                text: `¿Desea imprimir la Preventa?:`,
                icon: 'info',
                buttons: [
                    'No',
                    'Si'
                ],
            })
            if (isOK) {
                await this.onPrintPreventaPdf();
            }
        } catch (e) {
            notificarError("No se puede imprimir")
        }
    }

    getNormalizedPayload(payload) {
        return getPayloadFromPreVenta(payload);
    }

    showErrors(res) {
        const error = _.isObject(res.data) ? res.data : {msg: res.data, pos: -1}
        notificarError(error.msg)
        if (error.pos !== -1)
            $(`tr#det-${error.pos}`)
                .addClass("alert alert-danger")
                .focus()

        if (error.msg.includes("stock"))
            $("#BtnReloadProds").click();
    }

    render() {
        const {conf, puedeVender} = this.props;
        const {totales, isLoading} = this.state;

        return (
            <div className="w-100 mt-2 h-100">
                <div className="preventa row h-100">
                    <PreInvoiceSubForm
                        showSwitchStock={this.props.showSwitchStock}
                        permisosDescuento={this.props.permisosDescuento}
                        codigoValido={this.props.codigoValido}
                        defaultBgInProductDetail={this.props.defaultBgInProductDetail}
                        hideDocKeys={conf.disableSellBtn ? [BOLETA, FACTURA] : null}
                        showDefaultColor={this.props.showDefaultColor}
                        stockKey={this.props.stockKey}
                        prodsUrl={this.props.prodsUrl} preventaId={this.props.preventaId}
                        onChange={this.onChangeSubForm} conf={conf}
                        showModalPermisosDescuento={this.props.showModalPermisosDescuento}/>
                </div>

                <div className="totals__container mt-5 botones-desk foot" style={{zIndex: 1}}>
                    <div className="totals__container--item">
                        {parseFloat(totales.Gravado) > 0 ?
                            <p>
                                Gravado{" "}
                                <strong>
                                    {Number(totales.Gravado).toFixed(2)}
                                </strong>
                            </p>
                            : null
                        }
                        {parseFloat(totales.Inafecto) > 0 ?
                            <p>
                                Inafecto{" "}
                                <strong>
                                    {decimalAdjust('floor', totales.Inafecto, -2)}
                                </strong>
                            </p>
                            : null
                        }
                        {parseFloat(totales.Exonerado) > 0 ?
                            <p>
                                Exonerado{" "}
                                <strong>
                                    {decimalAdjust('floor', totales.Exonerado, -2)}
                                </strong>
                            </p>
                            : null
                        }
                        {parseFloat(totales.Gratuitas) > 0 ?
                            <p>
                                Gratuitas{" "}
                                <strong>
                                    {decimalAdjust('floor', totales.Gratuitas, -2)}
                                </strong>
                            </p>
                            : null
                        }
                        {parseFloat(totales.ICBPERPreventa) > 0 ?
                            <p>
                                ICBPER{" "}
                                <strong>
                                    {decimalAdjust('floor', totales.ICBPERPreventa, -2)}
                                </strong>
                            </p>
                            : null
                        }
                        {parseFloat(totales.IGVPreventa) > 0 ?
                            <p>
                                IGV{" "}
                                <strong>
                                    {Number(totales.IGVPreventa).toFixed(2)}
                                </strong>
                            </p>
                            : null
                        }
                        {parseFloat(totales.redondeo) > 0 ?
                            <p>
                                Redondeo <strong>
                                {decimalAdjust('round', totales.redondeo, -2)}
                            </strong>
                            </p>
                            : null
                        }
                    </div>
                    <div className="totals__container--item-total">
                            <span className="letra-total">{decimalAdjust('round', totales.totalMonto, -1)}
                            </span>
                    </div>
                    <div className="totals__container--item">

                        {this.props.hideDocKeys == VALE ?
                            <button
                                disabled={isLoading}
                                onClick={this.onAddPreInvoice}
                                className="generar__boton btn btn-success boton-generar-resp letra-total"
                            >
                                {isLoading ? 'Cargando' : 'Generar'}
                            </button>
                            :
                            <button
                                disabled={isLoading || !puedeVender}
                                onClick={this.onAddPreInvoice}
                                className="generar__boton btn btn-success boton-generar-resp letra-total"
                            >
                                {isLoading ? 'Cargando' : 'Generar'}
                            </button>
                        }

                        <MagellanStatus />

                    </div>
                </div>

                <div className="totals__container mt-5 botones-resp no-pad">
                    <div className="container-fluid no-pad">
                        <div className="row" style={{margin: "0"}}>
                            <div className="totals__container--item col-12">
                                {parseFloat(totales.Gravado) > 0 ?
                                    <p>
                                        Gravado{" "}
                                        <strong>
                                            {Number(totales.Gravado).toFixed(2)}
                                        </strong>
                                    </p>
                                    : null
                                }
                                {parseFloat(totales.Inafecto) > 0 ?
                                    <p>
                                        Inafecto{" "}
                                        <strong>
                                            {decimalAdjust('floor', totales.Inafecto, -2)}
                                        </strong>
                                    </p>
                                    : null
                                }
                                {parseFloat(totales.Exonerado) > 0 ?
                                    <p>
                                        Exonerado{" "}
                                        <strong>
                                            {decimalAdjust('floor', totales.Exonerado, -2)}
                                        </strong>
                                    </p>
                                    : null
                                }
                                {parseFloat(totales.Gratuitas) > 0 ?
                                    <p>
                                        Gratuitas{" "}
                                        <strong>
                                            {decimalAdjust('floor', totales.Gratuitas, -2)}
                                        </strong>
                                    </p>
                                    : null
                                }
                                {parseFloat(totales.ICBPERPreventa) > 0 ?
                                    <p>
                                        ICBPER{" "}
                                        <strong>
                                            {decimalAdjust('floor', totales.ICBPERPreventa, -2)}
                                        </strong>
                                    </p>
                                    : null
                                }
                                {parseFloat(totales.IGVPreventa) > 0 ?
                                    <p>
                                        IGV{" "}
                                        <strong>
                                            {Number(totales.IGVPreventa).toFixed(2)}
                                        </strong>
                                    </p>
                                    : null
                                }
                                {parseFloat(totales.redondeo) > 0 ?
                                    <p>
                                        Redondeo <strong>
                                        {decimalAdjust('round', totales.redondeo, -2)}
                                    </strong>
                                    </p>
                                    : null
                                }
                            </div>
                            <div className="container-fluid no-pad">
                                <div className="row col-12 no-pad m-0">
                                    <div className="totals__container--item-total col-6 pl-0"
                                         style={{backgroundColor: "#b2c3c0"}}>
                                        <div className="container-fluid d-flex justify-content-center"
                                             style={{height: "10vh", backgroundColor: "#01a59c"}}>
                                            <span
                                                className="letra-total centrar-total">{decimalAdjust('round', totales.totalMonto, -1)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="totals__container--item-total col-6 pr-0 no-pad">

                                        <button
                                            // disabled={isLoading}
                                            onClick={this.onAddPreInvoice}
                                            className="generar__boton btn btn-success boton-generar-resp letra-total"
                                        >
                                            {isLoading ? 'Cargando' : 'Generar'}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
