import React, { useState } from "react"
import { decimalAdjust } from "../../Global"
import { BotonAddPequeño, BotonMenosPequeño, BotonRemovePequeño } from "../Plantillas/Botones";


export function SelectedProduct({ menuIsActivated, onToggleMenu, product, onSubtract, onAdd, onRemove, }) {
    return <>
        <div className="lista-producto-promo-content3">
            <div className="lista-producto-promo3">
                <div className="nombre-prunitario3">
                <div className="nombre-producto3">{`${product.descripcion} ${product.unidadMedida}`}</div>
                    <div className="detalles-producto3">
                    <p className="cantidad-producto3">{product.Cantidad}</p>
                    <p className="precio-producto3">S/ {decimalAdjust("round", product.Precio, -2)}</p>
                    <p className="stock-producto3">S/ {decimalAdjust("round", product.Total, -2)}</p>
                    <button
                        className="btn-toggle-menu"
                        type="button"
                        onClick={onToggleMenu}
                    >
                        <i className="fa-solid fa-ellipsis-v"></i>
                    </button>

                    {menuIsActivated && (
                        <div className="menu-desplegable">
                            <BotonMenosPequeño type="button" title="Disminuir en 1" onClick={onSubtract}/>
                            <BotonAddPequeño type="button" title="Aumentar en 1" onClick={onAdd} />
                            <BotonRemovePequeño type="button" title="Quitar item" onClick={onRemove} />
                        </div>
                    )}
                    </div>
                </div>
            </div>
        </div>
    </>
}


export function SelectedProducts({ products, onSubtractQuantity, onAddQuantity, onRemoveProduct }) {
    const [activeMenu, setActiveMenu] = useState(null);
    
    const toggleMenu = (index) => {
        setActiveMenu(activeMenu === index ? null : index);
    };

    return <>
        <div className="fijado3">
            <div className="titulo-productos3">
                <p>CANTIDAD</p>
                <p></p>
                <p>PRECIO</p>
                <p></p>
                <p>TOTAL</p>
                <p></p>
            </div>
        </div>
        <div className="productos-promo-scroll3">
            <div className="lista-producto-promo-general3">
              { products.map((product, index) =>
                    <SelectedProduct
                        key={index}
                        product={product}
                        menuIsActivated={activeMenu == index}
                        onToggleMenu={() => toggleMenu(index)}
                        onSubtract={() => onSubtractQuantity(index)}
                        onAdd={() => onAddQuantity(index)}
                        onRemove={() => onRemoveProduct(index)}
                    />)
                }
            </div>
        </div>
    </>
}