import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import ReactExport from "react-data-export";
import "react-moment";
import moment from "moment";
import LinkButton from "../componentes/LinkButton";
import {BotonEditar} from "../componentes/Plantillas/Botones";
import {ACTION, ENTITY} from "contatrib-utils";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const styleCalendar = {
    border: "0.5px solid #acacac",
    borderRadius: "25px",
    outline: "none"
};

function getDataExportExcel(dataSet) {
    return [
        {
            columns: [
                {
                    title: "Fecha",
                    width: {wpx: 80}
                },
                {
                    title: "Código",
                    width: {wpx: 80}
                },
                {
                    title: "Alias",
                    width: {wpx: 120}
                },
                {
                    title: "Número Documento",
                    width: {wpx: 150}
                },
                {
                    title: "Razón Social",
                    width: {wpx: 380}
                },
                {
                    title: "Moneda",
                    width: {wpx: 80}
                },
                {
                    title: "Total",
                    width: {wpx: 80}
                },
                {
                    title: "Tipo Comprobante",
                    width: {wpx: 140}
                }
            ],

            data: dataSet.map(row => {
                return [
                    {
                        value: row.FechaEmision,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Codigo,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Alias,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.NroTipoDocumento,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.RazonSocial,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Abreviatura,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Total,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TipoComprobante,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    }
                ];
            })
        }
    ];
}

const BarCodeScannerListener = (props) => {
    const [scannedText, setScannedText] = useState('');

    const ignoredKeys = new Set([
        'Meta',
        'Shift',
        'CapsLock',
        'Control',
        'Alt',
        'Tab',
        'ArrowUp',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
    ]);
    

    const goToPreventa = (codPreventa) => {
        const regex = /^PV-\d+$/;

        if (!regex.test(codPreventa)) {
            setScannedText("");
            return;
        }
        
        const idPreventa = codPreventa.replace("PV-", "");
        props.onSubmitPreventa(idPreventa)
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            const { key } = event;

            if (key === 'Enter') {
                if (scannedText.trim()) {
                    goToPreventa(scannedText.toUpperCase());
                    setScannedText('');
                }
            } else if (!ignoredKeys.has(key)) {
                setScannedText((prev) => prev + event.key);
            }
        };
        
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [scannedText, goToPreventa]);

    return <></>
}

const GestionPreventasUI = props => {
    const [permisos, setPermisos] = useState({
        canUpdate: false,
        canDelete: false,
    })
    let tienePermisoCaja = props.tienePermisoCaja;

    useEffect(() => {
        fetchPermissions();
    }, [])

    const fetchPermissions = async () => {
        try {
            const response = await fetch(`/api/gestionPreventas/permisos`);
            const data = await response.json();
            setPermisos(data)
        } catch (e) {
            console.error(e)
        }
    }

    const onClickEditPreventa = (e, id) => {
        if (props.conf.useAccessCode) {
            const data = JSON.stringify({
                action: ACTION.UPDATE,
                entity: ENTITY.PREVENTA,
                entityId: id,
                redirect: `/preVentas/edit/${id}`,
            })
            props.history.push(`/check-access/?data=${data}`)
        } else {
            let btnPreventa = document.querySelector(`#BtnEditarPreventa-${id}`);
            window.sessionStorage.setItem('tienePermisoCaja', tienePermisoCaja);
            btnPreventa.click();
        }
    }

    const dataExport = getDataExportExcel(props.preventas);


    return (
        <>
            <section className="ventas-fpay-section-vh pt-5">
                <div className="container">
                    <BarCodeScannerListener onSubmitPreventa={(idPreventa) => onClickEditPreventa(null, idPreventa)}/>
                    <div className="row justify-content-between mb-3 mr-0 ml-0">
                        <div className="d-flex">
                            <LinkButton
                                className="btn-verde-mfp mb-3 mr-2"
                                to="/pre-invoice/add"
                            >
                                {/* <i className="fas fa-plus" /> */}
                                Nueva Preventa
                            </LinkButton>
                            <button
                                className="btn-verde-mfp mb-3"
                                onClick={props.actualizar}
                            >
                                {/* <i className="fas fa-plus" /> */}
                                Actualizar  
                            </button>
                        </div>
                        <div className="d-flex justify-content-end" align="right">
                            <div className="row mr-2 ml-0">
                                {/* <span className="align-self-center letra-fecha-fpay">Desde: </span> */}
                                <input
                                    type="date"
                                    onChange={props.handleCalendar}
                                    value={props.FechaPreventas}
                                    className="input__linea mr-1 linea-fecha-fpay"
                                    style={styleCalendar}
                                />
                            </div>
                            <div className="row mr-0 ml-0">
                                {/* <div className=""> */}
                                    <button className="btn btn-pdf-mfp ml-0 mr-2" style={{height:"fit-content"}} onClick={props.pdfViewer}>
                                        {<a className="">Descargar PDF</a>}
                                    </button>
                                {/* </div> */}
                                {/* <div className=""> */}
                                    <ExcelFile
                                        element={
                                            <button className="btn btn-excel-mfp">
                                                <a className="">Descargar Excel</a>
                                            </button>
                                        }
                                        filename="ReportePreventasPendientes"
                                    >
                                        <ExcelSheet dataSet={dataExport} name="Preventas"></ExcelSheet>
                                    </ExcelFile>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    <ReactTable
                        id="carrito"
                        data={props.preventas}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                        }
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Opciones",
                                        id: "IdPreventa",
                                        width: 150,
                                        accessor: d => d.IdPreventa,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["IdPreventa"]
                                            }),
                                        Cell: f => {
                                            return (
                                                <div align="center">
                                                    <BotonEditar hidden id={`BtnEditarPreventa-${f.original.IdPreventa}`}
                                                        to={"/preVentas/edit/" + f.original.IdPreventa}
                                                    />

                                                    {tienePermisoCaja ?
                                                        (
                                                            <button
                                                                className="boton__outline-pequeno--c"
                                                                title="procesar pre-venta"
                                                                onClick={props.handleProcesaPreventa(
                                                                    f.original.IdPreventa
                                                                )}
                                                            >
                                                                {<i className="fas fa-cash-register" />}
                                                            </button>

                                                        ) :
                                                        (
                                                            permisos.canUpdate && <BotonEditar
                                                                onClick={e => onClickEditPreventa(e, f.original.IdPreventa)}
                                                                className="mr-2"
                                                            />
                                                        )
                                                    }
                                                    { permisos.canDelete && <button
                                                        value={f.original.IdPreventa}
                                                        type="button"
                                                        className="boton__outline-pequeno--r ml-3"
                                                        title="eliminar pre-venta"
                                                        onClick={() => props.handleOnDeletePreventa(
                                                            f.original.IdPreventa
                                                        )}
                                                    >
                                                        {<i className="fas fa-trash-alt" />}
                                                    </button> }
                                                </div>
                                            );
                                        },

                                        filterAll: true
                                    },

                                    {
                                        Header: "Fecha",
                                        id: "fecha",
                                        width: 200,
                                        accessor: d => moment(d.FechaEmision).format("DD-MM-YYYY hh:mm:ss a"),
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["FechaEmision"] }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Usuario",
                                        id: "Usuario",
                                        width: 100,
                                        accessor: d => d.Usuario,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["Usuario"]
                                            }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Código",
                                        id: "Codigo",
                                        width: 150,
                                        accessor: d => d.Codigo,
                                        Cell: f => `PV-${f.original.IdPreventa}`,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["Codigo"] }),
                                        filterAll: true
                                    },

                                    {
                                        Header: "Alias",
                                        id: "Alias",
                                        width: 200,
                                        accessor: d => d.Alias,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["Alias"]
                                            }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Documento",
                                        id: "NroTipoDocumento",
                                        accessor: d => d.NroTipoDocumento,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["NroTipoDocumento"]
                                            }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Nombre/RS",
                                        id: "RazonSocial",
                                        accessor: d => d.RazonSocial,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["RazonSocial"]
                                            }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Moneda",
                                        id: "Abreviatura",
                                        width: 60,
                                        accessor: d => d.Abreviatura,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["Abreviatura"]
                                            }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Total",
                                        id: "Total",
                                        accessor: d =>
                                            d.Total.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }),
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["Total"]
                                            }),
                                        style: {
                                            textAlign: "right"
                                        },
                                        filterAll: true
                                    },
                                    {
                                        Header: "Comprobante",
                                        id: "TipoComprobante",
                                        width: 150,
                                        accessor: d => d.TipoComprobante,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["TipoComprobante"]
                                            }),
                                        filterAll: true
                                    },

                                ]
                            }
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        previousText="Anterior"
                        nextText="Siguiente"
                        noDataText="No se encontraron registros"
                        loadingText="Cargando..."
                        ofText="de"
                        rowsText="filas"
                        pageText="Página"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                    />
                </div>
            </section>
        </>
    );
};

export default GestionPreventasUI;
